import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/app/_api/api.service';
import { finalize } from 'rxjs/operators';
import FileSaver, { saveAs } from 'file-saver';
import { Distributor } from 'src/app/models/distributor';
import { Alerts } from 'src/app/utils/alerts';
import { Season } from 'src/app/models/season';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { User } from 'src/app/models';
declare var $: any;

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.sass']
})
export class ReportComponent implements OnInit, AfterViewInit {
  distributor: Distributor;
  loading: boolean = false;
  seasons: Season[] = [];

  accountExcel: boolean = false;
  accountForm: FormGroup;

  currentUser: User;

  constructor(private formBuilder: FormBuilder, private api: ApiService,private auth: AuthenticationService) { }

  ngOnInit() {
    this.accountForm = this.formBuilder.group({
      aDistributor: [null, Validators.required],
      aSeason: [null, Validators.required]
    });

    this.currentUser = this.auth.user;
    //console.log(this.currentUser);
  }

  ngAfterViewInit(): void {

    $('.select2-control').select2({ width: '100%', theme: 'bootstrap' });

    this.api.getAll('seasons/getSeasons').subscribe((seasons: Season[]) => {
      this.seasons = seasons;

      $('#seasonSelect,#boxesSelect').val(null).trigger('change');
    });

    $('#seasonSelect').on('change', () => {
      this.accountForm.controls['aSeason'].setValue($('#seasonSelect').val());
    });

    //console.log(this.currentUser.email);
    this.api.get('distributors/getDistributor', this.currentUser.email).subscribe(
      (distributor: Distributor) => {
        this.distributor = distributor;
        //console.log(this.distributor);
        //$('#distributionSelect,#returnSelect,#accountSelect').val(null).trigger('change');
      },
      (error: any) => {
        Alerts.AlertFailed(error.message);
      }
    );
  }

  getAccountReport(): void {
    this.loading = true;
    this.accountExcel = true;
    var distributor = this.distributor;
    this.api.getExcelFile('account/getAccountStatus', { Distributor: distributor.id, Season: this.accountForm.controls['aSeason'].value }).pipe(finalize(() => {
      this.loading = false;
      this.accountExcel = false;
    })).subscribe((data: any) => {
      var blob = new Blob([data.body], { type: 'application/vnd.ms-excel' });
      if (blob.size > 0) {
        var file = new File([blob], 'estado-cuenta-' + distributor.code.toLowerCase() + '.xlsx', { type: 'application/vnd.ms-excel' });
        FileSaver.saveAs(file);
      }
      this.accountForm.reset();
      $('#seasonSelect').val(null).trigger('change');
      $('#accountSelect').val(null).trigger('change');
    }, (error: any) => {
      Alerts.AlertFailed(error.message);
    });
  }

  get isAccountFormValid(): boolean {
    if (this.accountForm.controls['aSeason'].value)
      return true;
    return false;
  }
}
